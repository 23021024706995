import Index from '@v/index.vue'
import Login from '@v/login.vue'
import userInfo from '@v/userSys/userInfo.vue'
import userInfoQw from '@v/userSys/userInfoQw.vue'
import materialCheckDetail from '@v/scrm/material/materialCheckDetail.vue'
const routesJson = require('./routesJson.json')
const routesArray = [];
//router.afterEach((to, from) => {
//    wxAuth(window.location.protocol + '//' + window.location.host + '/'${item.fileName});
//});
//import  {wxAuth}  from '@/utils/wx-auth.ts'; // 授权文件
//wxAuth(window.location.protocol + '//' + window.location.host + '/userInfoQw');
for(let key in routesJson){
    routesJson[key].map(item=>{
        let routeItem = {}
        let routeItemOld = {}
        if(item.beforeEnter){
            routeItem = {
                path:key+item.path,
                beforeEnter:function(to, from, next){
                    console.log('beforeEnter',item.fileName)
                    next({path:`/${item.fileName}/1`})
                }
            }
        }else{
            routeItem = {
                path:key+item.path,
                name:item.fileName,
                alias:[item.alias||''],
                component:() =>import(`@/views${item.fileDir}/${item.fileName}.vue`),
                meta:{
                    title:item.routeName,
                    menuIndex:item.menuIndex||'',
                    keepAlive:item.keepAlive==undefined?false:item.keepAlive
                },

            }
            routeItemOld = {
                path:item.path,
                name:item.fileName,
                redirect:key+item.path

            }
            routesArray.push(routeItemOld)
        }



        routesArray.push(routeItem)
    })
}
console.log('arr',routesArray)

export const routesWeb = routesArray
export const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        alias:'/index',
        redirect: '/csms',
        children:routesWeb
    },{
        path: '/dashboard',
        name: 'Index',
        component: Index,
    },{
        path: '/dashboardscrm',
        name: 'Index',
        component: Index,
    },{
        path:'/login',
        name:'Login',
        component:Login
    } ,
    {
        path:'/userInfo',
        name: 'userInfo',
        component: userInfo
    },
    {
        path:'/userInfoQw',
        name: 'userInfoQw',
        component: userInfoQw
    },
    {
        path:"/materialcheckdetail/:id",
        name:'materialCheckDetail',
        component: materialCheckDetail,
    }

]